'use client';

import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import parse from 'html-react-parser';
import responsive from '@miniintern/styles/responsive';
import PopupXIcon from '@svg/popup/popup-X-button-24.svg';
import useModal from '#lib/hooks/useModal';
import { isAndroid } from '@miniintern/utils';
import { sendErrorToSentry } from '#lib/error';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import zIndexes from '#styles/zIndexes';
import { Tag, Button } from '@miniintern/ui';

interface PopupContainerBlockProps {
  isFullScreenAtMobile: boolean;
  mobileWidth?: string;
  hasTag: boolean;
  negativeType: boolean;
  hasButton: boolean;
}

const PopupContainerBlock = styled.div<PopupContainerBlockProps>`
  .popup-wrapper {
    z-index: ${zIndexes.popupBox};
    width: 456px;
    overflow: auto;
    border-radius: 6px;
    background-color: white;
    position: relative;
    margin-top: 0;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);

    &::-webkit-scrollbar {
      display: none;
      height: 0;
      width: 0;
    }

    ${({ hasButton }) =>
      hasButton &&
      css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 321px;
      `};

    @media (max-width: ${responsive.medium}) {
      width: 300px;
      max-height: calc(100vh - 100px);

      ${({ isFullScreenAtMobile }) =>
        isFullScreenAtMobile &&
        css`
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          border-radius: 0;
          overflow: hidden;
          min-height: calc(var(--vh, 1vh) * 100); //safari, chrome 하단 바 대응
        `}
    }

    @keyframes applicant-info-expand-height-animation {
      from {
        transform: transLateY(-200px);
      }
      to {
        transform: transLateY(0);
      }
    }
    will-change: transform;

    animation: applicant-info-expand-height-animation 0.3s;
  }

  .popup-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${({ hasTag }) => (hasTag ? '24px 88px 0 88px' : '21px 88px')};

    .popup-header-close-button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 32px;
      cursor: pointer;
      border: none;
      background-color: transparent;
      padding: 0;
    }

    @media (max-width: ${responsive.medium}) {
      padding: 12px 64px;
      right: 20px;

      .popup-header-close-button {
        right: 20px;
      }
    }
  }

  .popup-content-wrapper {
    overflow: auto;
    margin-bottom: auto;

    ${({ hasTag, isFullScreenAtMobile, hasButton }) =>
      hasTag
        ? css`
            padding: ${hasButton ? '28px 32px 0' : '28px 32px 32px'};
            @media (max-width: ${responsive.medium}) {
              padding: ${isFullScreenAtMobile ? '40px 20px 20px' : '24px 20px 20px'};
            }
          `
        : css`
            padding: ${hasButton ? '12px 32px 0' : '12px 32px 32px'};
            @media (max-width: ${responsive.medium}) {
              padding: ${hasButton ? '12px 20px 0' : '12px 20px 20px'};
            }
          `};

    ${({ isFullScreenAtMobile }) =>
      isFullScreenAtMobile &&
      css`
        @media (max-width: ${responsive.medium}) {
          height: 100vh;
        }
      `}
  }

  .popup-title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    word-break: keep-all;

    @media (max-width: ${responsive.medium}) {
      ${({ hasTag }) =>
        !hasTag &&
        css`
          font-size: 16px;
        `}
    }
  }

  .popup-button-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
    justify-content: space-between;
    padding: 0 32px 32px;
    z-index: 10;

    @media (max-width: ${responsive.medium}) {
      flex-direction: column;
      padding: 0 20px 20px;

      ${({ isFullScreenAtMobile }) =>
        isFullScreenAtMobile &&
        css`
          padding: 20px;
          box-shadow: 0 0 20px 4px rgb(0 0 0 / 12%);
        `}
    }
  }
`;
interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  isFullScreenAtMobile?: boolean;
  mobileWidth?: string;
  title?: string;
  tag?: string;
  negativeType?: boolean;
  confirmButton?: {
    title: string;
    onClick: (e?: any) => void;
    disabled?: boolean;
    isLoading?: boolean;
    gtmLabel?: string;
  };
  cancelButton?: {
    title: string;
    onClick?: () => void;
    preventCloseModal?: boolean;
    gtmLabel?: string;
  };
  preventRouting?: boolean;
  closeSvgGtmLabel?: string;
}

const PopupContainer: React.FC<IProps> = ({
  children,
  isFullScreenAtMobile = false,
  mobileWidth,
  title,
  tag,
  negativeType = false,
  confirmButton,
  preventRouting = false,
  cancelButton,
  closeSvgGtmLabel,
  ...props
}) => {
  const { closeModal } = useModal();
  const popupWrapperRef = useRef<HTMLDivElement>(null);
  //? safari 하단바 이슈
  const handleResize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    const routeChangeStart = () => {
      const isFullScreenAtMobile = popupWrapperRef.current?.clientHeight === window.innerHeight;
      if (preventRouting || !isFullScreenAtMobile || !isAndroid) return;
      closeModal();
      router.replace(pathname + (searchParams?.toString() || ''));
      throw 'Abort route change. Please ignore this error.';
    };
    router.events.on('routeChangeStart', routeChangeStart);
    return () => {
      window.removeEventListener('resize', handleResize);
      routeChangeStart && router.events.off('routeChangeStart', routeChangeStart);
    };
  }, [preventRouting, router, pathname, searchParams]);

  return (
    <PopupContainerBlock
      isFullScreenAtMobile={isFullScreenAtMobile}
      mobileWidth={mobileWidth}
      negativeType={negativeType}
      hasTag={!!tag}
      hasButton={!!confirmButton}
      data-testid="popup"
      data-gtm-label="exposure_popup"
      data-gtm-variable-popup-header-text={tag}
      {...props}
    >
      <div className="popup-wrapper" ref={popupWrapperRef}>
        {tag ? (
          <>
            <div className="popup-header-wrapper">
              <Tag label={tag} size="large" color={negativeType ? 'orange_500' : 'blue_500'} />
              <button
                type="button"
                className="popup-header-close-button"
                onClick={() => {
                  closeModal();
                }}
                {...(closeSvgGtmLabel && { 'data-gtm-label': closeSvgGtmLabel })}
              >
                <PopupXIcon />
              </button>
            </div>
            <div className="popup-content-wrapper">
              {tag && title && <p className="popup-title">{parse(title)}</p>}
              {children}
            </div>
          </>
        ) : (
          <>
            <div className="popup-header-wrapper">
              <p className="popup-title">{title}</p>
              <button
                type="button"
                className="popup-header-close-button"
                onClick={() => {
                  closeModal();
                }}
              >
                <PopupXIcon />
              </button>
            </div>
            <div className="popup-content-wrapper">{children}</div>
          </>
        )}

        {confirmButton && (
          <div className="popup-button-wrapper">
            {confirmButton && (
              <Button
                buttonType="primary"
                size="xLarge"
                disabled={confirmButton.disabled}
                // TODO: 로딩 처리
                // isLoading={confirmButton.isLoading}
                color={negativeType ? 'orange_500' : 'blue_500'}
                onClick={async e => {
                  const target = e.currentTarget;
                  try {
                    target.disabled = true;
                    await confirmButton.onClick();
                  } catch (err) {
                    sendErrorToSentry(err);
                  }
                  target.disabled = false;
                }}
                {...(confirmButton.gtmLabel && { 'data-gtm-label': confirmButton.gtmLabel })}
              >
                {confirmButton.title}
              </Button>
            )}
            {cancelButton && (
              <Button
                buttonType="line"
                size="xLarge"
                color="black"
                onClick={() => {
                  if (cancelButton.onClick) {
                    cancelButton.onClick();
                  }
                  if (!cancelButton.preventCloseModal) {
                    closeModal();
                  }
                }}
                {...(cancelButton.gtmLabel && { 'data-gtm-label': cancelButton.gtmLabel })}
              >
                {cancelButton.title}
              </Button>
            )}
          </div>
        )}
      </div>
    </PopupContainerBlock>
  );
};

export default React.memo(PopupContainer);
