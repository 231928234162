import { useRouter, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ModalType } from '#types/redux/coreModule';
import { coreActions } from '#modules/core';

const useModal = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const searchParams = useSearchParams();

  const closeModal = useCallback(() => {
    if (searchParams?.get('portal')) {
      router.back();
      return;
    }
    dispatch(coreActions.closeModal());
  }, []);

  const closeAllModal = useCallback(() => {
    dispatch(coreActions.closeAllModal());
  }, []);

  const openModal = useCallback(async (params: ModalType) => {
    dispatch(coreActions.setModal(params));
  }, []);

  return { openModal, closeModal, closeAllModal };
};

export default useModal;
