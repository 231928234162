import React from 'react';
import styled, { css } from 'styled-components';
import palette from '@miniintern/styles/palette';

export const colors = [
  'blue_500',
  'red_500',
  'orange_500',
  'yellow_500',
  'green_500',
  'rc_blue_500',
  'rc_red_500',
  'rc_orange_500',
  'rc_yellow_500',
  'rc_green_500',
  'skyblue_500',
  'lightgray',
  'gray_800',
  'black',
] as const;

export type TagColorName = (typeof colors)[number];

export type TagColorScheme = {
  [colorName in TagColorName]: {
    fill: TagColorStyle;
    stroke: TagColorStyle;
  };
};

type TagColorStyle = {
  textColor: string;
  backgroundColor: string;
  borderStyle: string;
};

const tagColor: TagColorScheme = {
  blue_500: {
    fill: {
      textColor: palette.blue_500,
      backgroundColor: palette.blue_100,
      borderStyle: `1px solid ${palette.blue_100}`,
    },
    stroke: {
      textColor: palette.blue_500,
      backgroundColor: 'transparent',
      borderStyle: `1px solid ${palette.blue_500}`,
    },
  },
  red_500: {
    fill: {
      textColor: palette.red_500,
      backgroundColor: palette.red_100,
      borderStyle: `1px solid ${palette.red_100}`,
    },
    stroke: {
      textColor: palette.red_500,
      backgroundColor: 'transparent',
      borderStyle: `1px solid ${palette.red_500}`,
    },
  },
  orange_500: {
    fill: {
      textColor: palette.orange_500,
      backgroundColor: palette.orange_100,
      borderStyle: `1px solid ${palette.orange_100}`,
    },
    stroke: {
      textColor: palette.orange_500,
      backgroundColor: 'transparent',
      borderStyle: `1px solid ${palette.orange_500}`,
    },
  },
  yellow_500: {
    fill: {
      textColor: palette.yellow_500,
      backgroundColor: palette.yellow_100,
      borderStyle: `1px solid ${palette.yellow_100}`,
    },
    stroke: {
      textColor: palette.yellow_500,
      backgroundColor: 'transparent',
      borderStyle: `1px solid ${palette.yellow_500}`,
    },
  },
  green_500: {
    fill: {
      textColor: palette.green_500,
      backgroundColor: palette.green_100,
      borderStyle: `1px solid ${palette.green_100}`,
    },
    stroke: {
      textColor: palette.green_500,
      backgroundColor: 'transparent',
      borderStyle: `1px solid ${palette.green_500}`,
    },
  },
  rc_blue_500: {
    fill: {
      textColor: palette.rc_blue_500,
      backgroundColor: palette.rc_blue_50,
      borderStyle: `1px solid ${palette.rc_blue_50}`,
    },
    stroke: {
      textColor: palette.rc_blue_500,
      backgroundColor: 'transparent',
      borderStyle: `1px solid ${palette.rc_blue_500}`,
    },
  },
  rc_red_500: {
    fill: {
      textColor: palette.rc_red_500,
      backgroundColor: palette.rc_red_100,
      borderStyle: `1px solid ${palette.rc_red_100}`,
    },
    stroke: {
      textColor: palette.rc_red_500,
      backgroundColor: 'transparent',
      borderStyle: `1px solid ${palette.rc_red_500}`,
    },
  },
  rc_orange_500: {
    fill: {
      textColor: palette.rc_orange_500,
      backgroundColor: palette.rc_orange_100,
      borderStyle: `1px solid ${palette.rc_orange_100}`,
    },
    stroke: {
      textColor: palette.rc_orange_500,
      backgroundColor: 'transparent',
      borderStyle: `1px solid ${palette.rc_orange_500}`,
    },
  },
  rc_yellow_500: {
    fill: {
      textColor: palette.rc_yellow_500,
      backgroundColor: palette.rc_yellow_100,
      borderStyle: `1px solid ${palette.rc_yellow_100}`,
    },
    stroke: {
      textColor: palette.rc_yellow_500,
      backgroundColor: 'transparent',
      borderStyle: `1px solid ${palette.rc_yellow_500}`,
    },
  },
  rc_green_500: {
    fill: {
      textColor: palette.rc_green_500,
      backgroundColor: palette.rc_green_100,
      borderStyle: `1px solid ${palette.rc_green_100}`,
    },
    stroke: {
      textColor: palette.rc_green_500,
      backgroundColor: 'transparent',
      borderStyle: `1px solid ${palette.rc_green_500}`,
    },
  },
  skyblue_500: {
    fill: {
      textColor: palette.rc_skyblue_500,
      backgroundColor: palette.rc_skyblue_100,
      borderStyle: `1px solid ${palette.rc_skyblue_100}`,
    },
    stroke: {
      textColor: palette.rc_skyblue_500,
      backgroundColor: 'transparent',
      borderStyle: `1px solid ${palette.rc_skyblue_500}`,
    },
  },
  lightgray: {
    fill: {
      textColor: palette.gray_600,
      backgroundColor: palette.gray_200,
      borderStyle: `1px solid ${palette.gray_200}`,
    },
    stroke: {
      textColor: palette.gray_600,
      backgroundColor: 'transparent',
      borderStyle: `1px solid ${palette.gray_400}`,
    },
  },
  gray_800: {
    fill: {
      textColor: palette.gray_800,
      backgroundColor: palette.gray_200,
      borderStyle: `1px solid ${palette.gray_200}`,
    },
    stroke: {
      textColor: palette.gray_800,
      backgroundColor: 'transparent',
      borderStyle: `1px solid ${palette.gray_400}`,
    },
  },
  black: {
    fill: {
      textColor: palette.white,
      backgroundColor: palette.gray_800,
      borderStyle: `1px solid ${palette.gray_800}`,
    },
    stroke: {
      textColor: palette.gray_800,
      backgroundColor: 'transparent',
      borderStyle: `1px solid ${palette.gray_800}`,
    },
  },
};

const sizeStyles = {
  large: css`
    height: 36px;
    padding: 0 18px;
    font-size: 14px;
    font-weight: 500;
  `,
  medium: css`
    height: 28px;
    padding: 0 14px;
    font-size: 13px;
    font-weight: 500;
  `,
  small: css`
    height: 20px;
    font-size: 12px;
    font-weight: 400;
  `,
};

const shapeStyles = {
  circle: css`
    border-radius: 100px;
  `,
  round: css`
    border-radius: 4px;
  `,
};

const TagBlock = styled.span<{
  size: 'small' | 'medium' | 'large';
  type: 'fill' | 'stroke';
  shape: 'circle' | 'round';
  color: keyof typeof tagColor;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  flex-shrink: 0;
  width: fit-content;
  padding: ${({ shape, size }) => (shape === 'round' && size === 'small' ? '0 4px' : '0 8px')};
  ${({ size }) => sizeStyles[size]}
  ${({ type, color }) =>
    color &&
    css`
      color: ${tagColor[color][type].textColor};
      background-color: ${tagColor[color][type].backgroundColor};
      border: ${tagColor[color][type].borderStyle};
    `};
  ${({ shape }) => shape && shapeStyles[shape]}
`;

const IconWrapper = styled.span<{
  color: keyof typeof tagColor;
  type: 'fill' | 'stroke';
}>`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  *[fill]:not([fill='none']) {
    fill: ${({ color, type }) => tagColor[color][type].textColor};
  }

  *[stroke]:not([stroke='none']) {
    stroke: ${({ color, type }) => tagColor[color][type].textColor};
  }
`;

/**
 * Tag 컴포넌트의 props 인터페이스 정의
 */
interface TagProps extends React.ComponentProps<'span'> {
  /**
   * 태그의 텍스트를 나타냅니다.
   */
  label: string;
  /**
   * 태그의 타입
   *
   * @default 'fill'
   */
  type?: 'fill' | 'stroke';
  /**
   * 태그의 색상
   *
   * @default 'blue_500'
   */
  color?:
    | 'blue_500'
    | 'red_500'
    | 'orange_500'
    | 'yellow_500'
    | 'green_500'
    | 'rc_blue_500'
    | 'rc_red_500'
    | 'rc_orange_500'
    | 'rc_yellow_500'
    | 'rc_green_500'
    | 'skyblue_500'
    | 'gray_800'
    | 'lightgray'
    | 'black';
  /**
   * 태그의 모양 (circle 또는 round)
   *
   * @default 'circle'
   */
  shape?: 'circle' | 'round';
  /**
   * 태그의 크기 (small, medium 또는 large)
   *
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * 태그의 왼쪽 아이콘
   */
  leftIcon?: React.ReactNode;
  /**
   * 태그의 오른쪽 아이콘
   */
  rightIcon?: React.ReactNode;
}

const Tag: React.FC<TagProps> = ({
  type = 'fill',
  size = 'medium',
  color = 'blue_500',
  shape = 'circle',
  label,
  leftIcon,
  rightIcon,
  ...props
}) => {
  return (
    <TagBlock size={size} type={type} color={color} shape={shape} data-testid="tag" {...props}>
      {leftIcon && (
        <IconWrapper color={color} type={type}>
          {leftIcon}
        </IconWrapper>
      )}
      {label}
      {rightIcon && (
        <IconWrapper color={color} type={type}>
          {rightIcon}
        </IconWrapper>
      )}
    </TagBlock>
  );
};

export default Tag;
