const zIndexes = {
  happyfolioFooter: 10,
  projectSelector: 10,
  projectAsideTap: 11, // must more than 10
  projectApplyButton: 15,
  header: 20,
  bannerArrow: 20,
  userMenu: 25,
  popupBox: 50,
  hazyLayer: 200,
  sideBarOverLay: 60,
  notification: 100,
  toaster: 201,
  sideBar: 100,
  recruitmentHeader: 60,
  listPageStickyHeader: 10,
  listPageBanner: 11,
};

export default zIndexes;
