export * from './removeTimezone';
export * from './makeUrlQueries';
export * from './getHyphen';
export * from './fromNow';
export * from './projectOtPeriodDate';
export * from './validateAddClass';
export * from './highlighting';
export * from './addEmptySomething';
export * from './formatTime';
export * from './formatToKoreanDate';
export * from './extractVideoIdFromUrl';
export * from './validateEmail';
export * from './validateUrl';
export * from './getRemainingDays';
export * from './insertHyphenForPhoneNumber';
export * from './insertHyphenForRegistrationNumber';
export * from './formatToKoreaNumber';
export * from './combination';
export * from './addHttp';
export * from './removeHttp';
export * from './checkForDuplicates';
export * from './makeShortCutString';
export * from './openNewWindow';
export * from './ellipsisText';
export * from './getIsMobile';
export * from './makeCenteredEllipsis';
export * from './makeTimeText';
export * from './getScrollbarWidth';
export * from './makeIntactQueryString';
export * from './getDate';
export * from './sleep';
export * from './makeMoneyString';
export * from './parseYoutubeTotalTime';
export * from './transformCompanyName';
export * from './cookieStringToObject';
export * from './resetIds';
export * from './getKoreanAge';
export * from './convertSecondsToTime';
export * from './numberWithCommas';
export * from './insertSpecificCharacterStyle';
export * from './stripHtml';
export * from './loadScript';
export * from './convertMoneyToWon';
export * from './projectPeriodDate';
export * from './getCookie';
export * from './formatBytes';
export * from './formatPhoneNumber';
export * from './unFormatPhoneNumber';
export * from './allowNumbersOnly';
export * from './sendTelegramError';
export const BLUR_DATA_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN8VQ8AAlkBa3ROiyoAAAAASUVORK5CYII=';
export const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
export const isAndroid = Boolean(userAgent.match(/Android/i));
