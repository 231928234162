import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CoreState, ModalType, ToasterPayload } from '#types/redux/coreModule';
import routes from '#constants/routes';

export const coreState: CoreState = {
  prevRouter: routes.home,
  toaster: {
    visible: false,
    message: '인터넷 환경을 확인해 주세요.',
    type: 'error',
    fn: null,
  },
  validateMode: false,
  isShownEmailAuthenticationHeader: true,
  modal: [],
};

const core = createSlice({
  name: 'core',
  initialState: coreState,
  reducers: {
    setPrevRouter: (state, action: PayloadAction<string>) => {
      state.prevRouter = action.payload;
    },
    setToaster: (state, action: PayloadAction<ToasterPayload>) => {
      const { visible, message, type, fn } = action.payload;
      state.toaster = {
        visible,
        message,
        type,
        fn,
      };
    },
    setValidateMode: (state, action: PayloadAction<boolean>) => {
      state.validateMode = action.payload;
    },
    setEmailAuthenticationHeader: (state, action: PayloadAction<boolean>) => {
      state.isShownEmailAuthenticationHeader = action.payload;
    },
    setModal: (state, action: PayloadAction<ModalType>) => {
      state.modal.push(action.payload);
    },
    closeModal: state => {
      state.modal.pop();
    },
    closeAllModal: state => {
      state.modal = [];
    },
  },
});

export const coreActions = core.actions;

export default core.reducer;
